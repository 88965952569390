<template>
    <div :class="menuVisible?'layout-menu':'layout-menu-small'">

        <!--s: Logo-->
        <div class="menu-logo">
            <span class="logo" v-if="menuVisible && accountStatus !== 'System'"><img :src="logoUrl"></span>
            <span class="logo-img" v-if="menuVisible && accountStatus === 'System'"></span>
            <span class="menu-visible" @click="onMenuShow">
                <Icon :custom="menuVisible?'menu-fold':'menu-unfold'" class="icon"/>
            </span>
        </div>
        <!--e: Logo-->

        <!--s: List-->
        <div class="menu-list">

            <div class="menu-box" :key="key" v-for="(menu,key) in accountStatus === 'System'?systemMenuList:menuList">
                <div class="menu-thead" :class="menu.menuCode" @click="menu.link?onLinks(menu.link):menuVisible?menu.visible = !menu.visible:null">
                    <Avatar class="avatar" v-if="menu.icon==='ios-home'">企</Avatar>
                    <Icon :custom="menu.icon" v-if="menu.icon!=='ios-home'" class="icon"/>{{ menuVisible?menu.name:'' }}
                    <Icon :type="menu.visible?'ios-arrow-up':'ios-arrow-down'" v-if="menu.childNode && menu.childNode.length !==0 && menuVisible " class="arrow"/>
                    <div class="menu-body-small" v-if="!menuVisible" :style="key === 0?'top:64px':'top:'+((key)*54+64)+'px'">
                        <ul>
                            <li :key="index" v-for="(childNode,index) in menu.childNode" :style="menu.childNode.length === 1?'border-radius: 5px;':''" :class="activeStatus === childNode.link?'active':''" @click="onLinks(childNode.link)">{{ childNode.name }}</li>
                        </ul>
                    </div>
                </div>
                <div class="menu-body" v-if="menu.visible && menuVisible">
                    <ul>
                        <li :key="index" v-for="(childNode,index) in menu.childNode" :class="activeStatus === childNode.link?'active':''" @click="onLinks(childNode.link)">{{ childNode.name }}</li>
                    </ul>
                </div>
            </div>

        </div>
        <!--e: List-->

    </div>
</template>

<script>
import Menu from './menu'
export default Menu
</script>
<style lang="less">
@import "menu";
</style>
