<template>
    <div class="layout-body">


        <!--s: Left menu-->
        <Menu/>
        <!--e: Left menu-->

        <!--s: Right content-->
        <div class="layout-content">

            <!--s: Header-->
            <Header />
            <!--e: Header-->

            <div class="content">
                <router-view />
            </div>

        </div>
        <!--e: Right content-->

    </div>
</template>
<script>
import Index from './index'
export default Index
</script>
<style lang="less">
@import "index";
</style>
