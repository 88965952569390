import Vue from 'vue'
import { Icon, Message,Avatar } from "view-design";
import { menuList, queryFilesInfoService, systemMenuList } from "@/service/system-service";
import { getUserSystemMenuList } from '@/service/user-service'
import { mapMutations, mapState } from "vuex";
Vue.component('Icon', Icon);
Vue.component('Avatar', Avatar);
/**
 * menu菜单
 * @type {{ menuList }}
 */
const Menu = {
    name:'Menu',
    computed: {
        ...mapState({
            accountStatus: state => state.userCenter.accountStatus, //账户状态
            menuVisible: state => state.system.menuVisible, //菜单显示状态
            userInfo:state => state.userCenter.userInfo, //用户信息
            taskType:state => state.system.taskType, //任务分类
        }),
    },
    data() {
        return {
            menuList:[],
            logoUrl:'',
            systemMenuList:systemMenuList,
            activeStatus:'',
        }
    },
    created() {
        if(this.accountStatus !=='System'){
            this.onUserSystemMenuList().then()
            if(this.userInfo.softwareLogo === ''){
                this.logoUrl = require("@/assets/Logo/menu-logo.png")
            }else{
                this.onImageUrl(this.userInfo.softwareLogo)
            }
        }
        this.activeStatus = this.$route.path
        if(this.$route.path === '/workflow/record/info'){
            this.activeStatus = '/workflow/record/list'
        }
        if(this.$route.path === '/workflow/edit/task'){
            if(this.taskType === 1){
                this.activeStatus = '/workflow/task'
            }
            if(this.taskType === 2){
                this.activeStatus = '/workflow/guidance/task'
            }
        }
    },
    methods:{
        ...mapMutations('system',['SET_SELECTED_MENU_ID','SET_MENU_VISIBLE']),
        /**
         * 关闭显示左侧menu
         */
        onMenuShow(){
            this.menuVisible?this.SET_MENU_VISIBLE(false):this.SET_MENU_VISIBLE(true)
        },
        /**
         * 跳转
         * @param link
         */
        onLinks(link){
            this.SET_SELECTED_MENU_ID([])
            this.$router.push(link)
        },
        /**
         * 获取权限菜单数据
         */
        async onUserSystemMenuList() {
            try {
                let params = {}
                const { code,data} = await getUserSystemMenuList(params)
                if(code === 'SUCCESS'){
                    this.menuList = []
                    for(let i in data){
                        for(let j in  menuList){
                            if(data[i].moduleCode === menuList[j].menuCode){
                                this.menuList.push(menuList[j])
                            }
                        }
                    }
                    this.menuList.push(menuList[2])
                    this.menuList.map((item, index) => {
                        if (item.menuCode == 'site-inspection') {
                            this.menuList.unshift(this.menuList.splice(index, 1)[0]);
                        }
                    });
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id) {
            try {
                let params = {
                    resourceName:id,
                }
                const {code,data} = await queryFilesInfoService(params);
                if(code === 'SUCCESS'){
                    this.logoUrl = data.url
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
            }catch (error) {
                console.log(error)
            }
        },
    },
    watch: {
        $route: {
            handler() {
                this.activeStatus = this.$route.path;
                if(this.$route.path === '/workflow/record/info'){
                    this.activeStatus = '/workflow/record/list'
                }
                if(this.$route.path === '/workflow/edit/task'){
                    if(this.taskType === 1){
                        this.activeStatus = '/workflow/task'
                    }
                    if(this.taskType === 2){
                        this.activeStatus = '/workflow/guidance/task'
                    }
                }
            },
            deep: true,
        }
    },
}
export default Menu
