/**
 * index
 * @type {{}}
 */
import Header from '@/components/Header/header.vue'
import Menu from '@/components/Menu/menu.vue'
import { mapMutations } from "vuex";

const Index = {
    name:'Index',
    components:{
        Header,
        Menu
    },
    data() {
        return {
            windowWidth:window.innerWidth, //获取窗口宽度
        }
    },
    created() {
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                if(window.innerWidth < 1200){
                    this.SET_MENU_VISIBLE(false)
                }
            })()
        };
    },
    methods: {
        ...mapMutations('system',['SET_MENU_VISIBLE']),
    },
}
export default Index
