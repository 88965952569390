<template>
    <div class="layout-header">

        <div class="header-logo">{{ userInfo.softwareTitle || 'AR智能服务平台' }}</div>

        <div class="header-icon header-right">
            <div class="link">
                <a :href="linkUrl === 'production'?'https://lingyun.ustep.cn/':'https://expert.ustep.cn/'">进入专家端  <Icon type="md-arrow-forward" /></a>
            </div>
            <Dropdown placement="bottom-start" class="user">
                <a href="javascript:">
                    <Avatar :src="userInfo.profilePictureUrl" size="small" />
                    <span class="header-user-name">{{ userInfo.name }}</span>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem>
                        <a href="javascript:" @click="onUserCenter">
                            <Icon custom="icon-user" />
                            个人中心
                        </a>
                    </DropdownItem>
                    <DropdownItem divided>
                        <a href="javascript:" @click="onSignOut">
                            <Icon custom="icon-sign-out" />
                            退出登录
                        </a>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>

        <Modal
            v-model="userModalVisible"
            title="个人中心"
            :closable="!speedSingleVisible"
            :mask-closable="!speedSingleVisible"
            footer-hide>
            <div class="device-modal">
                <span class="label">用户姓名：</span>
                <Input v-model="userEditParams.name" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">头像：</span>
                <div class="account-structural-image">
                    <div class="box">
                        <div class="add-icon" v-if="!speedSingleVisible">
                            <a href="javascript:">
                                <Icon type="ios-add" :size="30"/>
                            </a>
                        </div>
                        <div class="add-text" v-if="!speedSingleVisible">添加头像<br/>只能上传jpg/png，且文件大小不超过5M</div>
                        <div class="progress" v-if="speedSingleVisible">
                            <Progress :percent="complete" />
                        </div>
                        <input type="file" v-if="!speedSingleVisible" ref="pathClear" class="file" @change="onAccountFileChange($event)">
                        <div class="file-image" v-if="userEditParams.profilePictureUrl!==''">
                            <span class="image" :style="'background-image:url('+userEditParams.profilePictureUrl+')'"></span>
                            <div class="handle">
                                <div class="box">
                                    <a href="javascript:" @click="onImageAmplify(userEditParams.name,userEditParams.profilePictureUrl)"><Icon custom="look-big" :size="25"/></a>
                                    <a href="javascript:" @click="onClearImage"><Icon type="ios-trash-outline" :size="25"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="device-footer">
                <Button :disabled="speedSingleVisible" @click="accountModalVisible = false">取 消</Button>
                <Button :disabled="speedSingleVisible" type="primary" :loading="editLoading" @click="onSubmitCheck">确 定</Button>
            </div>
        </Modal>
        <!--s: Modal 查看大图-->
        <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc" />
        <!--e: Modal 查看大图-->
    </div>
</template>

<script>
import Header from './header'
export default Header
</script>
<style lang="less">
@import "header";
</style>
