/**
 * Header
 * @type {{}}
 */
import Vue from "vue";
import { Input,Button,Icon, Avatar, Dropdown, DropdownMenu, DropdownItem, Modal, Message,Progress } from "view-design";
Vue.component('Input', Input);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Avatar', Avatar);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Modal', Modal);
Vue.component('Progress', Progress);
import { mapMutations, mapState } from "vuex";
import Cookies from 'js-cookie';
import ViewImage from '@/components/ViewImage/viewImage.vue'
import { onSysncUploadFiles } from "@/utils/request-function";
import { systemUploadType } from "@/service/system-service";
import { updateUserInfo } from "@/service/user-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const Header = {
    name:'Header',
    components:{ ViewImage },
    computed: {
        ...mapState({
            userInfo: state => state.userCenter.userInfo, //获取状态管理用户中心
            accountStatus: state => state.userCenter.accountStatus, //获取状态管理用户状态
        }),
    },

    data() {
        return {
            userModalVisible:false, //个人中心弹框
            speedSingleVisible:false, //单文件上传进度可显示
            editLoading:false,
            imageName:'',
            imageSrc:'', //上传缩略图
            complete:0, //上传进度
            viewImageVisible:false,
            linkUrl:process.env.NODE_ENV,
            userEditParams:{
                name:'',
                profilePicture:'',
                profilePictureUrl:'',
            },
        }
    },
    created() {
        /**
         * 进度条数值
         */
        $event.$on(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE, (n) => {
            this.complete = n
        })
    },
    methods: {
        ...mapMutations('system',['SET_MENU_VISIBLE']),
        ...mapMutations('userCenter',['SET_USER_INFO']),
        /**
         * 退出登录
         */
        onSignOut() {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要退出系统吗？</p>',
                onOk: () => {
                    Cookies.remove('token');
                    if(this.accountStatus === 'System'){
                        this.$router.push('/login?account=system').then()
                    }else{
                        this.$router.push('/login').then()
                    }
                }
            });
        },
        /**
         * 弹出个人中心并付值
         */
        onUserCenter() {
            this.userModalVisible = true
            this.userEditParams.name = this.userInfo.name
            this.userEditParams.profilePicture = this.userInfo.profilePicture
            this.userEditParams.profilePictureUrl = this.userInfo.profilePictureUrl
        },
        /**
         * 清空
         */
        onClearImage() {
            this.$refs.pathClear.value = ''
            this.userEditParams.profilePicture = ''
            this.userEditParams.profilePictureUrl = ''
        },
        /**
         * 图片放大
         * @param name
         * @param src
         */
        onImageAmplify(name,src) {
            this.imageName = name
            this.imageSrc = src
            this.viewImageVisible = true
        },
        /**
         * 提交验证
         */
        onSubmitCheck() {
            if(this.userEditParams.name === ''){
                Message.error('请输入用户姓名！');
                return;
            }
            this.onUpdateUserInfo().then()
        },
        /**
         * 头像上传文件
         * @param event
         * @returns {Promise<void>}
         */
        async onAccountFileChange(event){
            const file =  event.target.files[0];
            this.fileType = file.type
            if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
                Message.warning('请上传正确的图片')
                return
            }
            if (file.size > 5 * 1024 * 1024) {
                Message.warning('请选择5M以内的图片')
                return
            }
            this.speedSingleVisible = true
            onSysncUploadFiles([file],systemUploadType.user_avatar_image).then((data)=>{
                this.speedSingleVisible = false
                this.userEditParams.profilePictureUrl = data[0].url
                this.userEditParams.profilePicture = data[0].resourceName
                this.complete = 0
            }).catch(()=>{
                this.speedSingleVisible = false
                this.complete = 0
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 修改用户信息
         */
        async onUpdateUserInfo() {
            try {
                this.editLoading = true
                let params = {
                    name:this.userEditParams.name,
                    profilePicture:this.userEditParams.profilePicture
                }
                const { code,data } = await updateUserInfo(params)
                if(code === 'SUCCESS'){
                    this.userModalVisible = false
                    this.SET_USER_INFO(data)
                    Message.success('修改成功！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.UPLOAD_PROGRESS_COMPLETE])
    }
}
export default Header
